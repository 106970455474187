import { enGB } from 'date-fns/locale';
import { PrescriberType } from '@customer-frontend/doctor';
import { Config } from '../type';
import { defaultConfig } from './default';

// TODO: https://linear.app/eucalyptus/issue/ENG-1792/[customer-frontend]-update-config
export const CompoundUKConfig: Config = {
  ...defaultConfig,
  analyticsBrand: 'compound',
  brand: 'compound-uk',
  loginUrl: '/login',
  title: 'Compound',
  consentRequired: true,
  prescriberType: PrescriberType.PRESCRIBER,
  stripeApi: 'PAYMENT_INTENT',
  country: 'United Kingdom',
  countryCode: 'GB',
  locale: 'en-GB',
  currency: 'GBP',
  emergencyNumber: '999',
  supportEmail: 'hello@myjuniper.co.uk',
  medicalEmail: 'hello@myjuniper.co.uk',
  rejectionContactLink: 'https://uk-juniper.zendesk.com/hc/en-au/requests/new',
  helpCentreUrl: 'https://uk-juniper.zendesk.com/hc/en-au',
  zendeskRequestUrl: 'https://uk-juniper.zendesk.com/hc/en-au/requests/new',
  weightPausingAccountZendeskParams: {
    tf_25854283026457: 'manage_my_account_uk',
    tf_15562527346841: 'account_pause_clone',
  },
  weightRefillNowZendeskParams: {
    tf_25854283026457: 'medical_support_uk',
    tf_15562018357657: 'med_err_clone',
  },
  weightResumeNowZendeskParams: {
    tf_25854283026457: 'general_question_uk',
    tf_25849723182233: 'general_enquirys',
  },
  trustpilotLink: 'https://uk.trustpilot.com/review/myjuniper.co.uk',
  contactUsUrl: 'https://www.myjuniper.co.uk/contact-page',
  dateConfig: {
    ...defaultConfig.dateConfig,
    datefnsLocale: enGB,
  },
  weightFaqReadingCollectionId: '5402325b-bab9-44b9-8622-eb3c54b2b430',
  selfServiceCancelEnabled: false,
  localisedAssets: {
    juniperArchway: '/assets/juniper-archway-2-gb.svg',
    juniperProductDesktopImage: '/assets/juniper-product-desktop-gb.png',
    juniperProductMobileImage: '/assets/juniper-product-mobile-gb.jpg',
    appWeightTracking: '/assets/app-weight-tracking-gb.png',
    medicationBox: '/assets/medication-box-gb.png',
    phoneCall: '/assets/phone-call.svg',
    program: {
      boxPen: '/assets/program/box-pen.webp',
      boxPen2x: '/assets/program/box-pen@2x.webp',
      boxPen3x: '/assets/program/box-pen@3x.webp',
      chat: '/assets/program/chat.webp',
      chat2x: '/assets/program/chat@2x.webp',
      chat3x: '/assets/program/chat@3x.webp',
      team: '/assets/program/team.webp',
      team2x: '/assets/program/team@2x.webp',
      team3x: '/assets/program/team@3x.webp',
      food: '/assets/program/food.webp',
      food2x: '/assets/program/food@2x.webp',
      food3x: '/assets/program/food@3x.webp',
      community: '/assets/program/community.webp',
      community2x: '/assets/program/community@2x.webp',
      community3x: '/assets/program/community@3x.webp',
      members: '/assets/program/members.webp',
      members2x: '/assets/program/members@2x.webp',
      members3x: '/assets/program/members@3x.webp',
      statisticOne: '/assets/program/statistic-one.svg',
      statisticTwo: '/assets/program/statistic-two.svg',
      statisticThree: '/assets/program/statistic-three.svg',
      staceyBeforeAfter: '/assets/program/staceybeforeafter.webp',
      staceyBeforeAfter2x: '/assets/program/staceybeforeafter@2x.webp',
      staceyBeforeAfter3x: '/assets/program/staceybeforeafter@3x.webp',
      rebekahBeforeAfter: '/assets/program/rebekahbeforeafter.webp',
      rebekahBeforeAfter2x: '/assets/program/rebekahbeforeafter@2x.webp',
      rebekahBeforeAfter3x: '/assets/program/rebekahbeforeafter@3x.webp',
    },
    consultation: {
      practitioners: {
        one: '/assets/consultation/care-team-stella.webp',
        two: '/assets/consultation/care-team-georgia.webp',
        three: '/assets/consultation/care-team-drvickers.webp',
        four: '/assets/consultation/care-team-lydia.webp',
        five: '/assets/consultation/care-team-ben.webp',
        six: '/assets/consultation/care-team-kim.webp',
      },
      initialScreening: '/assets/consultation/initial-screening-au.webp',
      paymentForTreatment: '/assets/consultation/payment-for-treatment-au.webp',
      medicalScreening: '/assets/consultation/medical-screening-au.webp',
      treatmentDelivery: '/assets/consultation/treatment-delivery-au.webp',
    },
  },
  externalSupportNumber: '1800 022 222',
  externalSupportLabel: 'HealthDirect',
  showOnboardingQuiz: true,
  consultationCreateAccountPage: true,
  appStoreLinks: {
    appStore:
      'https://apps.apple.com/gb/app/juniper-womens-weight-loss/id1661064221',
    playStore:
      'https://play.google.com/store/apps/details?id=vc.eucalyptus.juniper',
  },
  highCostWegovy: {
    productSkus: ['WRPSEMWEGPA', 'WRPSEMWEGPC', 'WRPSEMWEGPD'],
    highDosagePriceInCents: 24900,
  },
  verifyIdentity: true,
  shouldPresentPhoneFieldAtCreateAccount: false,
  flexiBrand: true,
  pausePurchaseFaqTypeformUrl: 'https://eucalyptus-vc.typeform.com/cancelform',
  purchasePrompts: {
    initialPurchaseConfirmPersonalDetails: true,
    initialPurchaseContents: 'condensed',
    initialPurchaseAddresses: new Set(['residential'] as const),
    initialPurchaseShowSimplifiedShippingAddressForm: false,
    supportShippingAddressValidation: false,
    collectDeliveryInstructionsUpfront: false,
    collectDeliveryInstructions: false,
    showCheckoutReassurance: false,
    showMarketingOptInCheckbox: true,
    showDeliveryConstraintCard: false,
    showTermsAndConditionsCheckbox: true,
    showZendeskSupportCard: false,
  },
  purchaseActivationFlow: {
    moneyBackGuaranteeDays: 25,
  },
  weightDashboardEnabled: false,
  urlPaths: {
    ...defaultConfig.urlPaths,
    about: '/program',
    termsAndConditions: '/terms-conditions',
  },
};
